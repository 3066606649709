import C from "./WSearchField.module.css";

import type { SearchFieldProps } from "react-aria-components";
import { Button, Input, SearchField } from "react-aria-components";
import { mcn } from "../../utils/baseProps.ts";
import { cn } from "../../utils/classNames.ts";
import { MaskIcon } from "../icon/MaskIcon.tsx";
import { type DebounceParams, useDebounceInput } from "./useDebounceInput.tsx";

interface WSearchFieldProps extends Omit<SearchFieldProps, "className">, Pick<DebounceParams, "debounceMs"> {
    className?: string;
    width?: string;
}

export function WSearchField({
    width,
    value,
    defaultValue,
    onChange,
    onBlur,
    debounceMs,
    ...props
}: WSearchFieldProps) {
    const inputProps = useDebounceInput({ debounceMs, onChange, value, defaultValue, onBlur });

    return (
        <SearchField aria-label="Search query" {...inputProps} {...mcn(cn(C.SearchField, width ?? "w-full"), props)}>
            {({ isEmpty }) => (
                <>
                    {isEmpty && <MaskIcon className={cn(C.Icon, "jp-icon-search size-0.875rem text-muted")} />}
                    <Input className={C.Input} />
                    <Button className={C.Button}>✕</Button>
                </>
            )}
        </SearchField>
    );
}
