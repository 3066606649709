import { type FocusEvent, useState } from "react";
import { useDebounceCallback } from "usehooks-ts";

export interface DebounceParams {
    debounceMs?: number;

    onChange?: (value: string) => void;
    value?: string;
    defaultValue?: string;
    onBlur?: (e: FocusEvent<Element, Element>) => void;
}

export function useDebounceInput({ onChange, debounceMs = 300, value, defaultValue, onBlur }: DebounceParams) {
    /** `undefined` means currently not editing */
    const [editingValue, setEditingValue] = useState<string | undefined>();

    const debouncedOnChange = useDebounceCallback(onChange ?? (() => {}), debounceMs);

    function onInputChange(value: string) {
        setEditingValue(value);
        debouncedOnChange(value);
    }

    const realValue = editingValue ?? value ?? defaultValue ?? "";

    return {
        value: realValue,
        onChange: onInputChange,
        onBlur: (e: FocusEvent) => {
            setEditingValue(undefined);
            onBlur?.(e);
        },
    };
}
